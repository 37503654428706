.coupon_header {
    display: flex;
}

.header_container {
    padding: 2%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header_content {
    flex-basis: 20%;
}

@media (max-width:767px) {

    .box_custom_style {
        margin: 15px !important;
        border-radius: 4px !important;
        border-width: 1px !important;
    }

    .data_view_custom {
        margin: 0 15px 0 15px;
        border-radius: 4px;
        border-style: solid;
        border-width: 0 1px;
    }

    .coupon_header {
        margin-right: 15px;
    }

    .header_container {
        flex-direction: row;
        align-content: center;
    }

    .header_content {
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 15px;
    }

}