.icon_wrapper {
    padding: 1%;
    display: flex;
    align-items: center;
}


@media (max-width:767px) {

    .icon_header {
        padding: 16px 0 !important;
        background: none !important;
        margin-bottom: 0 !important;
        box-shadow: none !important;
    }

    .icon_wrapper {
        margin: 0 15px 0 15px;
    }

}