.box_container {
  padding: 1%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  align-items: center;
}

.box_container div {
  padding: 10px;
  border-width: initial;
}

.stats {
  padding: 2%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 1152px) {
  .box_container {
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 767px) {
  .box_container {
    grid-template-columns: auto;
  }

  .stats {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}
