.container {
    padding: 1%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
}

.container > div {
    font-size: 13px;
    margin: 10px !important;
    border-width: 1px;
}

.container > button {
    font-size: 13px;
    padding: 10px;
}

.container > button > div {
    border-width: 1px;
}


@media (max-width:1152px) {

    .container {
        grid-template-columns: 50% 50%;
    }

    .last_column {
        grid-column-start: 2;
    }

    .enlarge_content {
        height: calc(100% - 20px);
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

}

@media (max-width:767px) {

    .container {
        grid-template-columns: auto;
        padding-left: 5%;
        padding-right: 5%;
    }

    .enlarge_content {
        height: auto;
    }

}