.nav_wrapper {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  flex: 1 1 100%;
  display: flex;
}

@media (max-width: 1130px) {
  .nav_wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 900px) {
  .hide_links {
    display: none;
  }
}
