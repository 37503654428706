body {
  margin: 0;
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
}

@media (max-width: 1151px) {
  .layout {
    width: 753px !important;
  }
}

@media (max-width: 767px) {
  .layout {
    display: none;
  }
  .footer {
    display: none;
  }
}

.mobilemsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  height: 69%;
}

@media (min-width: 767px) {
  .mobilemsg {
    display: none;
  }
}
